import React from "react"
import Layout from "../components/Layout"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import PhotoMasonry from "../components/Masonry"

export default function PsychologyArchitectureProjectDetails({
  data: { graphCmsPsychologyArchitecture },
}) {
  return (
    <Layout title="Katharina Kohler | Psychology + Architecture" description={graphCmsPsychologyArchitecture.heading + " - " + graphCmsPsychologyArchitecture.description + " by Katharina Kohler, Berlin, Germany"}>
      <Link
        to="/psychology_architecture"
        className="block hover:font-bold hover:underline"
      >
        &lt; back
      </Link>
      <div className="flex justify-center mt-10 lg:mt-20">
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="col-span-2 md:pr-4 flex justify-center">
            {graphCmsPsychologyArchitecture.images.length === 1 ? (
              <GatsbyImage
                key={graphCmsPsychologyArchitecture.images[0].localFile.id}
                alt={graphCmsPsychologyArchitecture.heading}
                image={getImage(
                  graphCmsPsychologyArchitecture.images[0].localFile
                )}
              />
            ) : (
              <PhotoMasonry>
                {graphCmsPsychologyArchitecture.images.map(image => (
                    <GatsbyImage
                      key={image.localFile.id}
                      alt={graphCmsPsychologyArchitecture.heading}
                      image={getImage(image.localFile)}
                      className="mb-4"
                    />
                ))}
              </PhotoMasonry>
            )}
          </div>
          <div className="md:col-span-1 relative mt-16 md:mt-0">
            <div className="absolute -right-5 md:right-0 -top-28 md:-top-20 lg:-top-28">
              <Link to="/psychology_architecture">
                <h1 className="text-3xl md:text-2xl lg:text-4xl text-right mb-8 font-black text-flieder uppercase">
                  Psychology
                  <br />
                  + Architecture
                </h1>
              </Link>
            </div>

            <h2 className="text-md sm:text-xl font-bold mt-2">
              {graphCmsPsychologyArchitecture.heading}
            </h2>
            <h3
              className={
                "text-xs sm:text-sm" + graphCmsPsychologyArchitecture.subheading
                  ? "hidden"
                  : ""
              }
            >
              {graphCmsPsychologyArchitecture.subheading}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: graphCmsPsychologyArchitecture.description.html,
              }}
              className="max-w-3xl"
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PsychologyArchitectureProjectPage($remoteId: ID) {
    graphCmsPsychologyArchitecture(remoteId: { eq: $remoteId }) {
      heading
      description {
        html
      }
      remoteId
      images {
        localFile {
          id
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
